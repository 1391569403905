import './App.css';
import Home from "./pages/home/home"
import Games from "./pages/games/games"
import Contact from "./pages/contact/contact"
import { useNavigate } from "react-router-dom";
import {Routes, Route, Link} from "react-router-dom"
import magicailogo from "./pages/games/magicaipuzzle/images/logo192.png"
import {useState} from "react"
import classnames from "classnames"

function App() {
  const [showMobile, setShowMobile] = useState(false);
  const [showWeb, setShowWeb] = useState(false);
  return (
    <div className="App">
      <header className="App-header">
        <div className="header__section header__section--mobile">
          <div className="header__section__title" onClick={()=>setShowMobile(true)}>Mobile Games</div>
          <div className={classnames({"header__section__games": true, "open": showMobile})}>
            <div className="header__link header__link--ktktkt"><Link className="ktktkt--link" to="/games/killthemkillthemkillthem" onClick={()=>{setShowWeb(false);setShowMobile(false);}}>KILL THEM KILL THEM<br/>KILL THEM</Link></div>
          </div>
        </div>
        <div className="header__link header__link--hoverfrog"><Link className="" to="/"><span className="hoverfrog--link__hoverfrog">HOVERFROG</span><span className="hoverfrog--link__digital">DIGITAL</span></Link></div>
        <div className="header__section header__section--web">
          <div className="header__section__title" onClick={()=>setShowWeb(true)}>Web Games</div>
          <div className={classnames({"header__section__games": true, "open": showWeb})}>
            <div className="header__link header__link--magicaipuzzle"><img src={magicailogo}/><a className="magicaipuzzle--link" href="https://magicaipuzzle.com/">Magic AI Puzzle</a></div>
          </div>
        </div>
        
      </header>
      <div className="Content">
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/games/*" element={<Games/>}/>
            <Route path="/contact" element={<Contact/>}/>
        </Routes>
      </div>
      <footer>
        <Link to="/contact">Contact</Link>
      </footer>
      <div className={classnames({"coverall":true, "show": showWeb||showMobile})} onClick={()=>{setShowWeb(false);setShowMobile(false);}}/>
    </div>
  );
}

export default App;
