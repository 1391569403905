import './home.scss';

function Home() {
  return (
    <div className="Home">
      <img className="hoverfrog" src="HoverFrog.png" alt="logo" />
    </div>
  );
}

export default Home;
