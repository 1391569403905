import './killthemkillthemkillthem.scss';
import {Routes, Route, Link} from "react-router-dom"
import Privacy from "./privacy/privacy"
import splash_image from "./images/splash.png"

function KillThemKillThemKillThem() {
  return (
      <Routes>
        <Route path="/" element={
          <div className="ktktkt">
            <img className="splash ktktkt__splash" src={splash_image}/>
            <div className="download-links">
              <div className="download-links__holder download-links__holder--google">
                <a href='https://play.google.com/store/apps/details?id=digital.HoverFrog.KillThemKillThemKillThem&hl=en-US&ah=yqGszSAZs05p22SO3l8EVtvTQ9I&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img className="store-badge store-badge--google" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
              </div>
              <div className="download-links__holder download-links__holder--apple">
                <a href="https://apps.apple.com/us/app/kill-them-kill-them-kill-them/id6449169138"><img className="store-badge store-badge--apple" alt='Download on the App Store' src="/app-store-badge.svg"/></a>
              </div>
            </div>
            <div className="legal">
              <span>Google Play and the Google Play logo are trademarks of Google LLC.</span>
              <span>Apple® and Apple logo® are registered trademarks of Apple Inc.</span>
            </div>
          </div>
        }/>
        <Route path="privacy" element={<Privacy/>}/>
    </Routes>
  );
}

export default KillThemKillThemKillThem;
