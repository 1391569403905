import './contact.scss';
import {Routes, Route} from "react-router-dom"

function Contact() {
  return (
    <Routes>
      <Route path="/" element={
        <div className="Contact">
          <p>For technical support, please email <a href="mailto:support@hoverfrog.digital">support@hoverfrog.digital</a></p>
          <p>For general inquiries, please email <a href="mailto:contact@hoverfrog.digital">contact@hoverfrog.digital</a></p>
        </div> 
      }/>
    </Routes>
    
  );
}

export default Contact;
