import './magicaipuzzle.scss';
import {Routes, Route, Link} from "react-router-dom"
import splash_image from "./images/splash.png"

function MagicAIPuzzle() {
  return (
      <Routes>
        <Route path="/" element={
          <div className="magicaipuzzle">
            <a href="https://magicaipuzzle.com"><img className="splash" src={splash_image}/></a>
          </div>
        }/>
    </Routes>
  );
}

export default MagicAIPuzzle;
