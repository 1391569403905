import './games.scss';
import {Routes, Route, Link} from "react-router-dom"
import KillThemKillThemKillThem from "./killthemkillthemkillthem/killthemkillthemkillthem"
import MagicAIPuzzle from "./magicaipuzzle/magicaipuzzle"

function Games() {
  return (
    <Routes>
      <Route path="/" element={
        <div className="Games">
          <Link className="ktktkt--link game--link" to="killthemkillthemkillthem">KILL THEM KILL THEM<br/>KILL THEM</Link>
        </div>
      }/>
      <Route path="killthemkillthemkillthem/*" element={<KillThemKillThemKillThem/>}/>
      <Route path="magicaipuzzle/*" element={<MagicAIPuzzle/>}/>
    </Routes>
    
  );
}

export default Games;
